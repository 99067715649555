import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getViewPreset } from 'dashboard/topFilter/OhsFilterUtils';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';

import { OhsOnboardingFilterPayload } from './OhsOnboardingModels';
import { OhsOnboardingServices } from './OhsOnboardingServices';

export interface OnboardingListRecordState {
  isLoading: boolean;
  onboardingList: ApiResListsType<any[]> | null;
  currentPage: number;
}

const initialState: OnboardingListRecordState = {
  isLoading: false,
  onboardingList: null,
  currentPage: 1,
};

const onboardingFilterOptions = (state: RootState): OhsOnboardingFilterPayload => {
  const { globalfilter, user, onboarding } = state;
  const filter = globalfilter.filterInfo;
  const userTier = user.user?.tierNum ?? 0;

  return {
    viewPreset: getViewPreset(userTier),
    next: onboarding.currentPage > 10 ? onboarding.onboardingList?.pagination.next ?? '' : '',
    page: state.onboarding.currentPage > 10 ? 0 : state.onboarding.currentPage,
    sort: { ...JSON.parse(String(filter.onboardingModule.sort)) },
    count: false,
    archived: filter.onboardingModule.archived,
  };
};

export const fetchOnboardingListAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined,
  { state: RootState }
>('onboarding/fetchOnboardingList', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const setOnboardingFilters: OhsOnboardingFilterPayload = {
    ...onboardingFilterOptions(state),
  };

  try {
    const response = await OhsOnboardingServices.getOnboardingList(setOnboardingFilters);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchOnboardingListCountAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined,
  { state: RootState }
>('onboarding/fetchOnboardingCountList', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const onboardingFiltersWithCount: OhsOnboardingFilterPayload = {
    ...onboardingFilterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await OhsOnboardingServices.getOnboardingList(onboardingFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setOnboardingIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingListAsync.pending, (state) => {
        state.isLoading = true;
        state.onboardingList = {
          items: [],
          pagination: state.onboardingList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchOnboardingListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const onboardingList = action.payload ?? [];
          const currentTotalPage: number = getOhsLocalStorage('onboardingListtotalPages');
          state.onboardingList = {
            items: [...onboardingList.items],
            pagination: {
              ...onboardingList.pagination,
              totalPages: Number(currentTotalPage),
            },
          };
          state.isLoading = false;
        }
      })
      .addCase(fetchOnboardingListAsync.rejected, (state) => {
        state.onboardingList = null;
      })
      .addCase(fetchOnboardingListCountAsync.fulfilled, (state, action) => {
        if (state.onboardingList && action.payload) {
          setOhsLocalStorage('onboardingListTotalPages', action.payload.pagination.totalPages);
          state.onboardingList.pagination.totalPages = action.payload.pagination.totalPages ?? 1;
        }
      });
  },
});
// Memoized Selectors
const onboardingState = (state: RootState) => state.onboarding;
export const getOhsOnboardingList = createSelector([onboardingState], (onboarding) => onboarding);

export const { setCurrentRegisterPage, setOnboardingIsLoading } = onboardingSlice.actions;
export const onboardingModuleReducer = onboardingSlice.reducer;
