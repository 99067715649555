import React, { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from 'tss-react/mui';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import OhsMfaVerifyModal from 'user/user-settings/mfa/verifyflows/OhsMfaVerifyModal';
import { OhsApiErrorCodes, checkContainsErrorCode } from 'global-services/api/OhsApiModels';
import OhsTaskRouteList from 'task/OhsTaskRouteList';
import OhsMfaEnrollModal from 'user/user-settings/mfa/enrollflows/OhsMfaEnrollModal';
import { SsoFlagName, setOhsLocalStorage } from 'global-services/OhsDataParse';
import { useQuery } from 'global-components/tab/OhsTab';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { fetchCurrentUserSettings, whoAmI } from 'user/OhsUserServices';
import { isAuthenticated, isMultiAuthenticated } from 'global-services/OhsAuthenticate';
import OhsUsersStoredSessions from 'user/session/OhsUsersStoredSessions';

import scLogo from '../angular-modules/build/img/sc-logo.png';
import OhsLoginForm, { LoginInputs } from './OhsLoginForm';
import OhsLoginFooter from './OhsLoginFooter';
import { OhsMultiUserLoginOption } from './multi/OhsMultiUserLoginOption';
import OhsMultiLogin from './multi/OhsMultiLogin';
import OhsLoginServices from './OhsLoginServices';
import useEnforceMfaHook from './OhsEnforceMfaHook';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '50%',
  },
}));

export default function OhsLoginHome() {
  const { checkMfaRequired } = useEnforceMfaHook();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const [isSSO, setIsSSO] = React.useState(false);
  const [enrollModalOpen, setEnrollModalOpen] = React.useState(false);
  const [verifyMfalModalOpen, setVerifyMfalModalOpen] = React.useState(false);
  const [userOptions, setUserOptions] = useState(new Array<OhsMultiUserLoginOption>());
  const idToken = query.get('id_token');

  const isUserAuthenticated = isAuthenticated();
  const isMultiUserAuthenticated = isMultiAuthenticated();

  const checkMfaVerifyRequired = async (error: any) => {
    // check if MFA is required and NO active MFA method
    const isMfaEnrollRequired = await checkMfaRequired();

    if (isMfaEnrollRequired) {
      setEnrollModalOpen(true); // enroll if MFA required and NO active MFA method
    } else {
      // check if MFA is required - open MFA verify Modal
      const mfaIsRequired = checkContainsErrorCode(error, OhsApiErrorCodes.MfaRequire);
      if (mfaIsRequired) setVerifyMfalModalOpen(true);
    }
  };

  const handleLoginResponse = async (response: any) => {
    if (response) {
      if (response?.success === false && isSSO === false) {
        await checkMfaVerifyRequired(response.error);
      }
      // Multiuser
      else if (response.multiuser === true) {
        const userSessions = new OhsUsersStoredSessions();

        const multiUserOptions = userSessions.updateMultiUserOptions(response);

        setUserOptions(multiUserOptions);
      } else {
        const from = location.state?.from?.pathname || OhsLoginServices.getHomeUrl(response.tier);
        navigate(from, { replace: true });
      }
    }
  };

  const submitLogin = async (data: LoginInputs) => {
    const response: any = await OhsLoginServices.loginByUsernamePassword(data.email, data.password);

    if (
      response?.success === false &&
      response.error &&
      response.error.code &&
      (response.error.code.includes(OhsApiErrorCodes.UserNotFound) ||
        response.error.code.includes(OhsApiErrorCodes.UserInvalidSession))
    )
      return;
    handleLoginResponse(response);
  };
  (window as any).submitLogin = submitLogin;

  const handleOtpVerifiedSuccess = async () => {
    const response: any = await whoAmI();

    if (response) {
      const from = location.state?.from?.pathname || OhsLoginServices.getHomeUrl(response.tier);

      navigate(from, { replace: true });
    }
  };

  const handleMfaEnrollClose = (currentStep: number) => {
    // Do not redirect to dashboard before step 4 (last step)
    if (currentStep === 4) {
      const url = generatePath(OhsTaskRouteList.active.url);
      navigate(url);
    }
  };

  React.useEffect(() => {
    if (idToken != null) {
      OhsLoginServices.loginBySSO(idToken).then((response: any) => {
        if (response?.success === false) {
          window.history.pushState({}, document.title, `/${ModuleUrl.Login}`);

          return;
        }

        handleLoginResponse(response);
        setIsSSO(true);
        setOhsLocalStorage(SsoFlagName, true);
      });
    }
  }, [idToken]);

  const forwardBaseOnTier = async () => {
    const currentUser = await fetchCurrentUserSettings();
    if (currentUser?._id) {
      const homeUrl = OhsLoginServices.getHomeUrl(currentUser.tier);
      if (homeUrl) navigate(homeUrl);
    } else navigate(`/${ModuleUrl.Login}`);
  };

  const fetchUserOptions = async () => {
    const options = await OhsLoginServices.getUserOptions();
    setUserOptions(options);
  };

  React.useEffect(() => {
    // Single user and authenticated
    if (isUserAuthenticated) {
      forwardBaseOnTier();
    }
    // logged Multi user with options token and  NO safetychampion token
    if (isMultiUserAuthenticated && !isUserAuthenticated) {
      fetchUserOptions();
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={scLogo} alt="Safety Champion" />
        <OhsLoginForm submitLogin={submitLogin} />
        <OhsMultiLogin userOptions={userOptions} />
        {/* <OhsMaintainInfo /> */}
        <OhsLoginFooter />
      </div>
      <OhsMfaVerifyModal
        title="Multi-factor authentication (MFA)"
        open={verifyMfalModalOpen}
        setModalOpen={setVerifyMfalModalOpen}
        onSuccess={handleOtpVerifiedSuccess}
      />
      <OhsMfaEnrollModal
        title="Multi-factor authentication (MFA)"
        open={enrollModalOpen}
        setModalOpen={setEnrollModalOpen}
        handleOnClose={handleMfaEnrollClose}
      />
    </Container>
  );
}
