import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import OhsLoginServices from 'login/OhsLoginServices';
import { getOhsUser } from 'user/userSlice';

import { useAppSelector } from './hooks';

function OhsDefaultRoute() {
  const navigate = useNavigate();
  const user = useAppSelector(getOhsUser);
  const headUrl = window.location.href.substring(
    0,
    window.location.href.indexOf(window.location.host) + window.location.host.length
  );
  const bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) + window.location.host.length
  );
  let adjustedURL = '';
  if (bodyUrl.indexOf('/#/') === 0) {
    adjustedURL = headUrl + bodyUrl.substring(2);
  } else if (user) {
    adjustedURL = OhsLoginServices.getHomeUrl(user.tier);
  } else {
    adjustedURL = `/${ModuleUrl.Login}`;
  }

  useEffect(() => {
    navigate(adjustedURL);
    window.location.assign(adjustedURL);
  });
  return <div style={{ display: 'none' }}>{adjustedURL}</div>;
}

export default OhsDefaultRoute;
