import React from 'react';

import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import { Box, Divider, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';

import OhsLoginServices from 'login/OhsLoginServices';
import { OhsApiErrorCodes, checkContainsErrorCode } from 'global-services/api/OhsApiModels';
import OhsMfaVerifyModal from 'user/user-settings/mfa/verifyflows/OhsMfaVerifyModal';
import OhsMfaEnrollModal from 'user/user-settings/mfa/enrollflows/OhsMfaEnrollModal';
import useEnforceMfaHook from 'login/OhsEnforceMfaHook';
import OhsComponentLoading from 'global-services/utils/OhsLoading';
import { SsoFlagName, getOhsLocalStorage } from 'global-services/OhsDataParse';
import { whoAmI } from 'user/OhsUserServices';
import OhsLogoutModal from 'login/OhsLogoutModal';
import useWindowSize from 'global-components/screenSize/useWindowSize';

import { OhsMultiUserLoginOption } from './OhsMultiUserLoginOption';
import scLogo from '../../angular-modules/build/img/sc-logo.png';

const useStyles = makeStyles()({
  root: {
    overflow: 'hidden',
    '& .MuiPaper-root': {
      overflow: 'hidden',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  hederMobile: {
    display: 'block',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  closeIcon: {
    position: 'absolute',
    right: '2px',
    top: '0',
    cursor: 'pointer',
    color: '#9e9e9e',
    fontSize: '25px!important',
    width: 'auto !important',
    height: 'auto !important',
    margin: '0 8px !important',
    '&:hover': {
      color: 'black',
      fontSize: '26px !important',
    },
  },
  wpLists: {
    overflow: 'auto',
  },
  scLogo: {
    float: 'left',
    marginRight: '20px',
    width: '120px',
  },
  logoMobile: {
    float: 'none',
  },
  signOutButton: {
    fontWeight: '400',
    fontSize: '2.125rem',
    lineHeight: '1.235',
    color: 'rgba(0, 0, 0, 0.6)',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export interface OhsMultiLoginPlaceDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  userOptions: OhsMultiUserLoginOption[];
}

export default function OhsMultiLoginPlaceDialog(props: OhsMultiLoginPlaceDialogProps) {
  const screenSize = useWindowSize();
  const { isLoading, userTier, checkMfaRequired } = useEnforceMfaHook();
  const [multiUser, setMultiUser] = React.useState<string>('');
  const [verifyMfalModalOpen, setVerifyMfalModalOpen] = React.useState(false);
  const [enrollModalOpen, setEnrollModalOpen] = React.useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const isSso = getOhsLocalStorage(SsoFlagName);
  const { onClose, open, userOptions } = props;

  const handleListItemClick = async (option: OhsMultiUserLoginOption) => {
    setMultiUser(option.user._id);
    const response: any = await OhsLoginServices.multiUserLogin(
      option.user._id,
      option.sessionToken ?? ''
    );
    // check if MFA is required and NO active MFA method
    const isMfaEnrollRequired = await checkMfaRequired();

    if (isMfaEnrollRequired && !isSso) {
      setEnrollModalOpen(true); // enroll if MFA required and NO active MFA method
    } else {
      const hasErrorCode = checkContainsErrorCode(response.error, OhsApiErrorCodes.MfaRequire);
      // RE-OPEN MFA verify modal if "The provided token has an ongoing active session."
      const hasActiveSession = checkContainsErrorCode(
        response.error,
        OhsApiErrorCodes.UserInvalidSession
      );
      if ((hasErrorCode || hasActiveSession) && !isSso) {
        setVerifyMfalModalOpen(true);
      } else {
        onClose(option.user._id);
        const from = location.state?.from?.pathname || OhsLoginServices.getHomeUrl(response.tier);

        navigate(from, { replace: true });
      }
    }
  };

  const handleMfaEnrollClose = (currentStep: number) => {
    // Do not redirect to dashboard before step 4 (last step)
    if (currentStep === 4) {
      const from = location.state?.from?.pathname || OhsLoginServices.getHomeUrl(userTier);

      navigate(from, { replace: true });
    }
    onClose(multiUser);
  };

  const handleOtpVerifiedSuccess = async () => {
    const response: any = await whoAmI();

    if (response) {
      onClose(multiUser);
      const from = location.state?.from?.pathname || OhsLoginServices.getHomeUrl(response.tier);

      navigate(from, { replace: true });
    }
  };

  return (
    <>
      <Box width={400}>
        <Dialog aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
          <Box
            px={5}
            pt={4}
            className={clsx({
              [classes.header]: true,
              [classes.hederMobile]: screenSize.width < 600,
            })}
          >
            <Icon
              onClick={() => setLogoutModalOpen(true)}
              className={classes.closeIcon}
              name="close"
              data-testid="modal-close"
            />

            <img
              className={clsx({
                [classes.scLogo]: true,
                [classes.logoMobile]: screenSize.width < 600,
              })}
              src={scLogo}
              alt="Safety Champion"
            />
            <Box py={3}>
              <Typography variant="h4" fontWeight="bold">
                Login As
              </Typography>
              <Typography style={{ color: 'black' }}>Pick a user to log into</Typography>
              <Typography style={{ color: 'black', fontWeight: 'bold' }}>
                Safety Champion Software
              </Typography>
            </Box>
          </Box>
          {isLoading && (
            <Box width={430}>
              <OhsComponentLoading />
            </Box>
          )}
          <List className={classes.wpLists}>
            {!isLoading &&
              userOptions
                ?.sort((x, y) => {
                  if (x.blocked === y.blocked) {
                    return 0;
                  }
                  return x.blocked ? 1 : -1;
                })
                .sort((x, y) => {
                  if (x.inProgress === y.inProgress) {
                    return 0;
                  }
                  return x.inProgress ? 1 : -1;
                })
                .map((item, index) => (
                  <div key={index}>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => handleListItemClick(item)}
                      key={item.user._id}
                      disabled={item.blocked === true || item.inProgress === true}
                      data-testid={`multiLogOpt${index}`}
                    >
                      <Box px={4} py={2}>
                        {(() => {
                          if (item.blocked) {
                            return (
                              <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                                Limited User Access
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        {(() => {
                          if (item.inProgress) {
                            return (
                              <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                                In Progress
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        {(() => {
                          if (item.user.tier.type === 'core.tier.T2') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.orgAdminTier.name} (Org Admin)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T3') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.orgTier.name} (Organisation)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T4') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.workplaceTier?.name} (Workplace)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T5') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.workerGroupTier?.name} (Worker Group)
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        <Typography style={{ fontWeight: 'bold' }}>
                          Profile: {item.user.profile.title}
                        </Typography>
                        {(() => {
                          if (item.user.tier.type === 'core.tier.T4') {
                            return (
                              <Typography className={classes.pos} color="textSecondary">
                                {`${item.orgTier.name}`}
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T5') {
                            return (
                              <Typography className={classes.pos} color="textSecondary">
                                {`${item?.workplaceTier?.name} < ${item.orgTier.name}`}
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                      </Box>
                    </ListItem>
                  </div>
                ))}
          </List>
          <Box px={6} py={2} sx={{ backgroundColor: '#f3f3f3', cursor: 'pointer' }}>
            <Typography
              className={classes.signOutButton}
              color="textSecondary"
              onClick={() => setLogoutModalOpen(true)}
              variant="h4"
            >
              Log out
            </Typography>
          </Box>
        </Dialog>
      </Box>
      <OhsMfaVerifyModal
        title="Multi-factor authentication (MFA)"
        open={verifyMfalModalOpen}
        setModalOpen={setVerifyMfalModalOpen}
        onSuccess={handleOtpVerifiedSuccess}
      />
      <OhsMfaEnrollModal
        title="Multi-factor authentication (MFA)"
        open={enrollModalOpen}
        setModalOpen={setEnrollModalOpen}
        handleOnClose={handleMfaEnrollClose}
      />
      <OhsLogoutModal open={logoutModalOpen} setLogoutModalOpen={setLogoutModalOpen} />
    </>
  );
}
