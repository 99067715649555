import {
  ApiResListsType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { listActiveTaskForModule } from 'task/OhsTaskServices';
import { TaskListsRecord } from 'task/OhsTaskTypes';
import OhsFetchAll from 'global-services/OhsFetchAll';

import { AllocatedListType, OhsChemicalListsFilterPayload } from './models/OhsChemicalModel';
import OhsChemicalAddRecord from './models/OhsChemicalAddRecord';
import OhsChemicalRecord from './models/OhsChemicalRecord';
import OhsChemicalTaskRecord from './models/OhsChemicalTaskRecord';
import parseListAllocations from './utils/OhsParseListAllocations';
import {
  OhsChemicalAllocationLocations,
  OhsChemicalAllocationRecord,
} from './models/OhsChemicalAllocation';

export const addChemical = async (payload: OhsChemicalAddRecord) => {
  const res: OhsChemicalAddRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Create,
    payload
  );
  return res;
};

export const editChemical = (payload: OhsChemicalAddRecord) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Edit, payload);
};

export const editChemicalLocations = (payload: Partial<OhsChemicalAddRecord>) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.EditLocations,
    payload
  );
};

export const getChemicalListsWithoutTasks = (
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};
export const parseChemicalTaskLists = (
  chemicalLists: ApiResListsType<any[]> | null,
  taskList: TaskListsRecord[] | null
): ApiResListsType<any[]> | null => {
  const chemList = chemicalLists?.items ?? [];

  if (chemList.length > 0 && taskList) {
    const chemicalWithTasks = chemList.map((item: OhsChemicalRecord) => {
      const chemicalRecord = {
        ...item,
        dateDue: '',
      };
      taskList?.map((taskItem: TaskListsRecord) => {
        if (item?._id === taskItem?.for?._id) {
          chemicalRecord.dateDue = taskItem?.dateDue || '';
        }
        return null;
      });

      return chemicalRecord;
    }) as any[];
    const updateChemicalTasksList = {
      ...chemicalLists,
      items: chemicalWithTasks,
    } as unknown as ApiResListsType<any[]>;
    return updateChemicalTasksList;
  }
  return chemicalLists;
};

const getChemicalLists = (
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getChemicalListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const chemicalLists = await getChemicalListsWithoutTasks(filterInfo);
    if (chemicalLists && chemicalLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(chemicalLists);
      });
    }
    const taskList = await OhsFetchAll(listActiveTaskForModule, {
      archived: filterInfo?.archived ?? false,
      modules: [ModuleType.Chemical],
    });
    const taskListItems = taskList?.items || null;

    const chemicalListWithTasks = parseChemicalTaskLists(
      chemicalLists,
      taskListItems
    ) as ApiResListsType<any[]> | null;

    return new Promise((resolve) => {
      resolve(chemicalListWithTasks);
    });
  };

  return getChemicalListsReq();
};

export const getChemicalAllocatedList = (
  chemicalListIDs: string[]
): Promise<AllocatedListType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.AllocatedList, {
    _ids: chemicalListIDs,
  });
};

export const archiveUnarchiveChemical = (chemicalId: string | undefined, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: chemicalId,
    }
  );
};
export const deleteChemicalRecord = (chemicalId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Delete, {
    _id: chemicalId,
  });
};

export const getChemicalDetails = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsChemicalRecord> => {
  const res: OhsChemicalRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsChemicalRecord();
};

export const getChemicalTaskRecord = async (
  _id: string,
  taskId: string
): Promise<OhsChemicalTaskRecord> => {
  const res: OhsChemicalTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Chemical,
      },
    }
  );
  if (!res) {
    return new OhsChemicalTaskRecord();
  }

  return res;
};
export const signOffChemical = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.SignOff, payload);

export const editTaskChemical = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.EditTask, payload);

export const getChemicalListsWithAllocations = (
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getChemicalListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const chemicalList = await getChemicalLists(filterInfo);
    // exit if list result is empty
    if (chemicalList && chemicalList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(chemicalList);
      });
    }

    // get allocated records from chemicals _ids
    const getIDs = chemicalList?.items.map((item: OhsChemicalRecord) => item._id) as string[];
    const allocatedList = await getChemicalAllocatedList(getIDs);
    // map allocated records to chemicallist
    const chemicalListWithAllocations = parseListAllocations(
      chemicalList,
      allocatedList
    ) as ApiResListsType<OhsChemicalRecord[]> | null;

    return new Promise((resolve) => {
      resolve(chemicalListWithAllocations);
    });
  };

  return getChemicalListsReq();
};

export const getChemicalAllocationLists = (
  _id: string
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.AllocationList, {
    _id,
  });
};

interface AllocateChemicalToWorkplaceProps {
  _id: string;
  to: {
    type: TierType;
    _id: string;
  };
  locations?: OhsChemicalAllocationLocations[];
}
export const allocateChemicalToWorkplaceSelf = (
  payload: AllocateChemicalToWorkplaceProps,
  options?: OhsApiRequestOptions
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Allocate,
    {
      ...payload,
    },
    options
  );
};

export const deAllocateChemicalToWorkplaceSelf = (
  payload: AllocateChemicalToWorkplaceProps,
  options?: OhsApiRequestOptions
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Deallocate,
    {
      ...payload,
    },
    options
  );
};

export default getChemicalLists;
