import React from 'react';

import { Button, ModalDescription } from 'semantic-ui-react';

import OhsModal from 'global-components/modal/OhsModal';
import OhsMessageBox from 'global-components/message-box/OhsMessageBox';
import { removeLocalStorage } from 'global-services/OhsDataParse';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsLoginServices from './OhsLoginServices';

interface Props {
  open: boolean;
  setLogoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function OhsLogoutModal(props: Props) {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const handleClose = () => {
    props.setLogoutModalOpen(false);
  };

  const handleTrustLogout = async () => {
    OhsLoginServices.logout(user?.email ?? '', false);
    removeLocalStorage('userData');
    props.setLogoutModalOpen(false);
  };

  // Filters out the session associated with the current user's email.
  // Saves the updated array of active sessions back to local storage.
  const handleClearUserLogout = async () => {
    OhsLoginServices.logout(user?.email ?? '', true);
    removeLocalStorage('userData');
    props.setLogoutModalOpen(false);
  };
  const handleCancel = async () => {
    props.setLogoutModalOpen(false);
  };

  return (
    <OhsModal
      open={props.open}
      title="Log out"
      onClose={handleClose}
      ContentNode={
        <ModalDescription>
          <OhsMessageBox
            title="Log out of safetychampion.online?"
            description="Trusting this browser will save your authentication and system settings,
             you will still be required to enter your user name and password to log back in."
          />
        </ModalDescription>
      }
      actionNode={
        <div style={{ width: '100%' }}>
          <Button floated="right" onClick={() => handleTrustLogout()} color="green">
            Trust and Log Out
          </Button>
          <Button onClick={() => handleClearUserLogout()}>Forget and Log Out</Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </div>
      }
    />
  );
}

export default OhsLogoutModal;
