import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

import { OhsMaterialIconButton } from 'global-components/buttons/OhsMaterialButton';
import OhsLogoutModal from 'login/OhsLogoutModal';
import OhsLoginServices from 'login/OhsLoginServices';
import { OhsMultiUserLoginOption } from 'login/multi/OhsMultiUserLoginOption';
import { isMultiAuthenticated } from 'global-services/OhsAuthenticate';

import OhsSwitchWorkplaceMenu from './OhsSwitchWorkplaceMenu';

const useStyles = makeStyles()((theme: Theme) => ({
  signOutButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function OhsLogoutButton() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const [loginOptions, setLoginOptions] = useState<OhsMultiUserLoginOption[]>([]);

  const { classes } = useStyles();

  const handleClickLoginOutButton = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    if (isMultiAuthenticated() === true) {
      OhsLoginServices.getUserOptions().then((userOptions) => {
        if (userOptions.length > 1) {
          setLoginOptions(userOptions);
          setAnchorEl(target);
        } else {
          setLogoutModalOpen(true);
        }
      });
    } else {
      setLogoutModalOpen(true);
    }
  };
  return (
    <>
      <OhsMaterialIconButton
        color="inherit"
        onClick={handleClickLoginOutButton}
        size="small"
        className={classes.signOutButton}
      >
        <Icon name="sign out" />
      </OhsMaterialIconButton>
      {anchorEl && (
        <OhsSwitchWorkplaceMenu
          loginOptions={loginOptions}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      <OhsLogoutModal open={logoutModalOpen} setLogoutModalOpen={setLogoutModalOpen} />
    </>
  );
}
