import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import OhsLoginServices from 'login/OhsLoginServices';
import { OhsMultiUserSessionToken } from 'global-services/constants/OhsStorageNames';

import OhsMultiLoginPlaceDialog from './OhsMultiLoginPlaceDialog';
import { OhsMultiUserLoginOption } from './OhsMultiUserLoginOption';

interface Props {
  userOptions: OhsMultiUserLoginOption[];
}
export default function OhsMultiLogin(props: Props) {
  const { userOptions } = props;
  const [open, setOpen] = useState(false);
  const [userOptionsList, setUserOptionsList] = useState<OhsMultiUserLoginOption[]>([]);

  useEffect(() => {
    if (userOptions && userOptions.length > 0) {
      setUserOptionsList(userOptions);
      setOpen(true);
    } else if (Cookies.get(OhsMultiUserSessionToken)) {
      OhsLoginServices.getUserOptions().then((options) => {
        if (options && options.length > 0) {
          setUserOptionsList(options);
          setOpen(true);
        }
      });
    }
  }, [userOptions]);

  return <OhsMultiLoginPlaceDialog open={open} onClose={() => {}} userOptions={userOptionsList} />;
}
