import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { OhsUser } from './OhsUser';
import { fetchCurrentUser } from './OhsUserServices';

export interface OhsUserState {
  user: OhsUser | null;
  isLoading: boolean;
}

const initialState: OhsUserState = {
  user: null,
  isLoading: false,
};

export const fetchCurrentUserAsync = createAsyncThunk('user/fetchCurrentUser', async () => {
  const response = await fetchCurrentUser();
  return response;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCurrentUserAsync.fulfilled, (state, action) => {
        const newUserData = JSON.stringify(new OhsUser(action.payload));

        state.user = JSON.parse(newUserData);
        state.isLoading = false;
      })
      .addCase(fetchCurrentUserAsync.rejected, (state) => {
        state.user = null;
        state.isLoading = false;
      });
  },
});

// Memoized Selectors
const userInfo = (state: any) => state.user.user as OhsUser;
const userLoadingState = (state: any) => state.user.isLoading;

export const getOhsUser = createSelector([userInfo], (user) => user);
export const getOhsUserLoadingState = createSelector([userLoadingState], (user) => user);

export const userReducer = userSlice.reducer;
