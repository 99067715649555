import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import { OhsUser } from 'user/OhsUser';
import { VisitorLocalArriveListName } from 'visitor/models/OhsVisitorRecordField';

import { OhsVisitorFilterPayload, VisitorAllocListRes } from '../OhsVisitorModels';
import OhsVisitorSiteRecordResponse from './models/OhsVisitorSiteRecordResponse';
import { OhsVisitorSiteActivityItem, OhsVisitorSiteClone } from './models/OhsVisitorSiteModels';

export const addVisitorSite = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteCreate,
    payload
  );
};

export const editVisitorSite = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.SiteEdit, payload);
};

export const getVisitorSite = async (_id: string): Promise<OhsVisitorSiteRecordResponse> => {
  const res: OhsVisitorSiteRecordResponse | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteFetch,
    { _id }
  );
  return res || new OhsVisitorSiteRecordResponse();
};

export const getVisitorSiteLists = (
  filterInfo: OhsVisitorFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.SiteList, {
    filter: filterInfo,
  });
};

export const getVisitorSiteAllocationList = (
  visitorId: string
): Promise<ApiResponseType<VisitorAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteAllocationList,
    {
      _id: visitorId,
    }
  );
};

export const allocateVisitorSite = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [] };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => item._id);
  } else {
    payload.to = [allocationItem._id];
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteAllocate,
    payload
  );
};

export const deAllocateVisitorSite = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [] };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => item._id);
  } else {
    payload.to = [allocationItem._id];
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteDeallocate,
    payload
  );
};

export const archiveVisitorSite = (payload: any, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    archived ? OhsApiRequestName.SiteArchive : OhsApiRequestName.SiteUnArchive,
    payload
  );
};

export const cloneVisitorSite = (payload: OhsVisitorSiteClone): Promise<any | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.SiteClone,
    payload
  );
};

export const getVisitorSiteActivityLists = (
  siteId: string,
  filterInfo: OhsVisitorFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.VisitList, {
    site: { _id: siteId },
    filter: filterInfo,
  });
};

// Store arrive response for leave search
export const storeVisitorSignInLocalRecord = (
  user: OhsUser,
  record: OhsVisitorSiteActivityItem
) => {
  const newRecord = record;
  let visitorSignInRecordList: Array<OhsVisitorSiteActivityItem>;
  newRecord.createdBy = {
    type: user.type,
    _id: user._id,
    name: user.name,
    email: user.email,
  };

  if (localStorage.getItem(VisitorLocalArriveListName)) {
    visitorSignInRecordList = JSON.parse(localStorage.getItem(VisitorLocalArriveListName)!);
  } else {
    visitorSignInRecordList = new Array<OhsVisitorSiteActivityItem>();
  }
  visitorSignInRecordList.push(record);
  localStorage.setItem(VisitorLocalArriveListName, JSON.stringify(visitorSignInRecordList));
};

export const visitorSiteArrive = async (user: OhsUser, payload: any) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.VisitArrive,
    payload
  );
  if (res?._id) {
    storeVisitorSignInLocalRecord(user, res);
  }
  return res;
};

export const removeVisitorSignInLocalRecord = (id: string) => {
  let visitorSignInRecordList: Array<OhsVisitorSiteActivityItem>;
  if (localStorage.getItem(VisitorLocalArriveListName)) {
    visitorSignInRecordList = JSON.parse(localStorage.getItem(VisitorLocalArriveListName) || '');
    const newVisitValue = (visitorSignInRecordList || []).filter(
      (item: OhsVisitorSiteActivityItem) => item._id !== id
    );
    localStorage.setItem(VisitorLocalArriveListName, JSON.stringify(newVisitValue));
  }
};

export const visitorSiteLeave = async (payload: any) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.VisitLeave,
    payload
  );
  if (res?._id) {
    removeVisitorSignInLocalRecord(res?._id);
  }
  return res;
};

export const visitorSiteBulkLeave = async (payload: any) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.VisitBulkLeave,
    payload
  );
  if (!res?.error) {
    const ids = payload._ids;
    ids.forEach((id: string) => removeVisitorSignInLocalRecord(id));
  }
  return res;
};

export const fetchVisitorSignInLocalRecord = (user: OhsUser, visitorId: string) => {
  const localSignInRecordList: Array<OhsVisitorSiteActivityItem> = JSON.parse(
    localStorage.getItem(VisitorLocalArriveListName)!
  );

  if (localSignInRecordList) {
    const targetRecord = localSignInRecordList.find((item) => item._id === visitorId);
    if (targetRecord) {
      const currentUserMatchesCreator = targetRecord.createdBy._id === user._id;
      if (currentUserMatchesCreator) {
        return targetRecord;
      }
    }
  }
  return null;
};

export const getVisitorSignInLocalRecordList = (
  user: OhsUser,
  searchKey: string
): Array<OhsVisitorSiteActivityItem> => {
  const removeAllSpaces = (str: string) => str.replace(/\s+/g, '');
  const visitorSignInRecordList = new Array<OhsVisitorSiteActivityItem>();

  const formatText = (str: string) => str.toLocaleLowerCase().trim();
  const formattedSearchKey = formatText(searchKey);

  if (formattedSearchKey && localStorage.getItem(VisitorLocalArriveListName)) {
    const localSignInRecordList: Array<OhsVisitorSiteActivityItem> = JSON.parse(
      localStorage.getItem(VisitorLocalArriveListName)!
    );
    for (let index = 0; index < localSignInRecordList.length; index += 1) {
      const element = localSignInRecordList[index];
      const nameMatch =
        element.visitor.pii.name && formatText(element.visitor.pii.name) === formattedSearchKey;
      const emailMatch =
        element.visitor.pii.email && formatText(element.visitor.pii.email) === formattedSearchKey;
      const phoneAndCodeMatch =
        element.visitor.pii.phoneCountryCode &&
        element.visitor.pii.phone &&
        removeAllSpaces(`${element.visitor.pii.phoneCountryCode}-${element.visitor.pii.phone}`) ===
          removeAllSpaces(formattedSearchKey);
      const phoneMatch =
        element.visitor.pii.phone &&
        element.visitor.pii.phone === removeAllSpaces(formattedSearchKey);
      if (
        element.createdBy._id === user._id &&
        (nameMatch || emailMatch || phoneAndCodeMatch || phoneMatch)
      ) {
        visitorSignInRecordList.push(element);
      }
    }
  }
  return visitorSignInRecordList;
};
